<div class="d-flex w-100 mb-4">
  <dx-tabs
    #withText
    [(selectedIndex)]="tabsSelectedIndex"
    [dataSource]="tabsWithText"
    width="auto"
    (onSelectionChanged)="onTabsSelectionChanged()"
  ></dx-tabs>
  <div
    class="dx-field-value w-100"
    [ngClass]="{ 'd-none': tabsSelectedIndex != 4 }"
  >
    <dx-date-range-box
      class="d-flex w-100"
      [(value)]="currentValue"
      displayFormat="dd/MM/yyyy"
      (onValueChanged)="onDateChanged($event)"
    >
    </dx-date-range-box>
  </div>
</div>

<div class="container-fluid dashboard-container">
  <!-- Tabs and Date Range -->

  <!-- Summary Cards -->
  <div class="row mb-4">
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow summary-card">
        <div class="label">Toplam Satış</div>
        <div class="value">{{ totalSales | tryFormat }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow summary-card">
        <div class="label">Toplam Sipariş</div>
        <div class="value">{{ totalOrders }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow summary-card">
        <div class="label">Toplam İndirim</div>
        <div class="value">{{ totalDiscount | tryFormat }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow summary-card">
        <div class="label">Toplam Vergi</div>
        <div class="value">{{ totalTax | tryFormat }}</div>
      </div>
    </div>
  </div>

  <!-- Main Charts Section -->
  <div class="row mb-4">
    <!-- Sales Report Chart -->
    <div class="col-lg-7">
      <div class="card px-3 py-2 shadow">
        <dx-chart
          id="chart"
          [dataSource]="salesInfo"
          [valueAxis]="[
            {
              name: 'total',
              position: 'right',
              showZero: true,
              constantLines: [
                {
                  value: 80,
                  color: '#fc3535',
                  dashStyle: 'dash',
                  width: 2,
                  label: { visible: false }
                }
              ],
              tickInterval: 20,
              valueMarginsEnabled: false
            }
          ]"
        >
          <dxi-series valueField="pos" name="Pos"></dxi-series>
          <dxi-series valueField="yemekSepeti" name="Yemek Sepeti"></dxi-series>
          <dxi-series valueField="getir" name="Getir"></dxi-series>
          <dxi-series
            valueField="siparisSaati"
            name="Sipariş Saati"
          ></dxi-series>
          <dxi-series valueField="trendyol" name="Trendyol"></dxi-series>
          <dxi-series
            axis="total"
            type="spline"
            valueField="total"
            name="Toplam"
            color="#008fd8"
          ></dxi-series>
          <dxo-common-series-settings argumentField="name" type="stackedBar">
          </dxo-common-series-settings>
          <dxo-legend
            verticalAlignment="top"
            horizontalAlignment="center"
            itemTextPosition="left"
          ></dxo-legend>
          <dxi-value-axis name="total" position="left">
            <dxo-grid [visible]="true"></dxo-grid>
          </dxi-value-axis>
          <dxo-title text="Satış Raporu"> </dxo-title>
          <dxo-export [enabled]="true"></dxo-export>
          <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
          </dxo-tooltip>
        </dx-chart>
      </div>
    </div>

    <!-- Best-Selling Categories Treemap -->
    <div class="col-lg-5">
      <div class="card px-3 py-2 shadow">
        <dx-tree-map
          id="treemap"
          [dataSource]="bestSellingCategories"
          title="En Çok Satan Kategoriler"
          valueField="sales"
          nameField="category"
          argumentField="category"
        >
          <dxo-label
            [visible]="true"
            [font]="{ size: 12, weight: 500, color: '#000' }"
            [textOverflow]="'ellipsis'"
            [wordWrap]="'normal'"
          >
          </dxo-label>
          <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
          </dxo-tooltip>
          <dxo-export [enabled]="true"></dxo-export>
        </dx-tree-map>
      </div>
    </div>
  </div>

  <!-- Additional Charts -->
  <div class="row mb-4">
    <!-- Order Totals Candlestick -->
    <div class="col-lg-6">
      <div class="card px-3 py-2 shadow">
        <dx-chart
          id="candlestick"
          [dataSource]="orderTotals"
          title="Sipariş Toplamları"
        >
          <dxi-series
            type="candlestick"
            openValueField="open"
            highValueField="high"
            lowValueField="low"
            closeValueField="close"
            argumentField="timeFrame"
            innerColor="red"
          ></dxi-series>
          <dxo-tooltip
            [enabled]="true"
            [customizeTooltip]="customizeTooltipCandleStick"
          >
          </dxo-tooltip>
          <dxo-reduction color="red"></dxo-reduction>

          <dxo-export [enabled]="true"></dxo-export>
        </dx-chart>
      </div>
    </div>

    <!-- Sales Channels Pie Chart -->
    <div class="col-lg-3">
      <div class="card px-3 py-2 shadow">
        <dx-pie-chart
          id="pie"
          title="Satış Kanalları"
          [dataSource]="saleChannels"
        >
          <dxo-legend
            verticalAlignment="top"
            horizontalAlignment="center"
            itemTextPosition="left"
          ></dxo-legend>
          <dxi-series argumentField="provider" valueField="total">
            <dxo-label
              [visible]="true"
              position="columns"
              [customizeText]="customizeLabel"
            >
              <dxo-font [size]="16"></dxo-font>
              <dxo-connector [visible]="true" [width]="0.5"></dxo-connector>
            </dxo-label>
          </dxi-series>
          <dxo-size [width]="500"></dxo-size>
          <dxo-export [enabled]="true"></dxo-export>
        </dx-pie-chart>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="card px-3 py-2 shadow">
        <dx-chart title="En Çok Satılan Ürünler" [dataSource]="topProducts">
          <dxi-series
            argumentField="productName"
            valueField="salesCount"
            type="bar"
            name="Satış Adedi"
          ></dxi-series>
          <dxi-series
            argumentField="productName"
            valueField="totalRevenue"
            type="bar"
            name="Toplam Gelir"
          ></dxi-series>
          <dxo-legend [visible]="true"></dxo-legend>
          <dxo-tooltip [enabled]="true"></dxo-tooltip>
        </dx-chart>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-lg-4">
      <div class="card px-3 py-2 shadow">
        <div class="label">En Son Siparişler</div>
        <dx-data-grid
          id="gridContainer"
          keyExpr="orderSerialNo"
          [dataSource]="latestOrders"
          [showBorders]="true"
          [columnAutoWidth]="true"
          [rowAlternationEnabled]="true"
        >
        <dxi-column dataField="orderSerialNo" caption="Sipariş No"> </dxi-column>
        <dxi-column dataField="orderDateTime" caption="Sipariş Zamanı" dataType="date" format="dd/MM/yyyy HH:mm"></dxi-column>
        <dxi-column dataField="orderType" caption="Sipariş Tipi"> </dxi-column>
        <dxi-column dataField="totalAmount" caption="Sipariş Tutarı"> </dxi-column>
        <dxi-column dataField="orderStatus" caption="Sipariş Durumu"> </dxi-column>
        </dx-data-grid>
      </div>
    </div>

    <!-- Order Status Line Chart -->
    <div class="col-lg-4">
      <div class="card px-3 py-2 shadow">
        <dx-chart title="Satış Trendleri" [dataSource]="salesTrends">
          <dxi-series
            argumentField="timeFrame"
            valueField="totalAmount"
            type="line"
            name="Toplam Satış"
          ></dxi-series>
          <dxi-series
            argumentField="timeFrame"
            valueField="totalQuantity"
            type="line"
            name="Satış Adedi"
          ></dxi-series>
          <dxo-legend [visible]="true"></dxo-legend>
          <dxo-tooltip [enabled]="true"></dxo-tooltip>
        </dx-chart>
      </div>
    </div>

    <!-- Average Daily Sales Bar Chart -->
    <div class="col-lg-4">
      <div class="card px-3 py-2 shadow">
        <div id="heatmapCanvas"></div>
      </div>
    </div>
  </div>
</div>
