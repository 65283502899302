<app-page-header
  [titleText]="'Sipariş Listesi'"
  [hasAddButton]="false"
></app-page-header>

<div class="container-fluid dx-card dx-wide-card px-0">
  <div class="row align-items-center">
    <div class="col-md-4">
      <dx-tabs
        #withIconAndText
        [(selectedIndex)]="selectedTabIndex"
        [dataSource]="tabs"
        [orientation]="'horizontal'"
        [stylingMode]="'secondary'"
        [iconPosition]="'start'"
        [itemTemplate]="'tab-item'"
      >
        <div *dxTemplate="let data of 'tab-item'; let index = index">
          <div class="d-flex align-items-center tab-title">
            {{ data }}
            <span
              *ngIf="orderCounts[index] > 0"
              class="badge badge-danger ml-3"
              >{{ orderCounts[index] }}</span
            >
          </div>
        </div>
      </dx-tabs>
    </div>

    <div class="col">
      <div class="d-flex justify-content-end align-items-center">
        <div class="mr-2">Otomatik Yenile</div>
        <dx-switch #switch [value]="false"></dx-switch>
        <dx-button class="mx-3 ml-md-5" icon="refresh" (onClick)="reload()">
        </dx-button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="selectedTabIndex == 0">
  <dx-accordion
    #accordion
    class="dx-card wide-card custom-accourdion"
    [dataSource]="accordionPanels"
    [collapsible]="true"
    [multiple]="true"
    [animationDuration]="300"
    [selectedItems]="accordionPanels"
  >
    <div *dxTemplate="let data of 'title'; let index = index">
      <div class="d-flex align-items center">
         <span
          *ngIf="index == 0 ? onlineOrders.length > 0 : activeOnlineOrders.length > 0"
          class="badge badge-danger mr-1"
          >{{ index == 0 ? onlineOrders.length : activeOnlineOrders.length }}</span
        > 
        <div class="header">
          {{ data }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let item of 'item'; let index = index">
      <app-order-table
        (onUpdateOrderClicked)="updateOrderStatus($event)"
        (onCancelOrderClicked)="openCancelReasonModal($event)"
        [dataSource]="index == 0 ? onlineOrders : activeOnlineOrders"
      ></app-order-table>
    </div>
  </dx-accordion>
</ng-container>

<ng-container *ngIf="selectedTabIndex == 1"> 
  
  <dx-accordion
    #accordion
    class="dx-card wide-card custom-accourdion"
    [dataSource]="accordionOnlinePanels"
    [collapsible]="true"
    [multiple]="true"
    [animationDuration]="300"
    [selectedItems]="accordionOnlinePanels"
  >
    <div *dxTemplate="let data of 'title'; let index = index">
      <div class="d-flex align-items center">
        <span
          *ngIf="index == 0 ? activePosOrders.length > 0 : activePosOrders.length > 0"
          class="badge badge-danger mr-1"
          >{{ index == 0 ? activePosOrders.length : activePosOrders.length }}</span
        >
        <div class="header">
          {{ data }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let item of 'item'; let index = index">
      <app-order-table
        (onUpdateOrderClicked)="updateOrderStatus($event)"
        (onCancelOrderClicked)="openCancelReasonModal($event)"
        [dataSource]="activePosOrders"
      ></app-order-table>
    </div>
  </dx-accordion>


</ng-container>

<ng-container *ngIf="selectedTabIndex == 2"> 
  

  <dx-accordion
    #accordion
    class="dx-card wide-card custom-accourdion"
    [dataSource]="accordionComplatePanels"
    [collapsible]="true"
    [multiple]="true"
    [animationDuration]="300"
    [selectedItems]="accordionComplatePanels"
  >
    <div *dxTemplate="let data of 'title'; let index = index">
      <div class="d-flex align-items center">
        <span
          *ngIf="index == 0 ? completedOrders.length > 0 : completedOrders.length > 0"
          class="badge badge-danger mr-1"
          >{{ index == 0 ? completedOrders.length : completedOrders.length }}</span
        >
        <div class="header">
          {{ data }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let item of 'item'; let index = index">
      <app-order-table
        (onUpdateOrderClicked)="updateOrderStatus($event)"
        (onCancelOrderClicked)="openCancelReasonModal($event)"
        [dataSource]="completedOrders"
      ></app-order-table>
    </div>
  </dx-accordion>
</ng-container>


<dx-popup
  [width]="'600px'"
  [height]="'300px'"
  [showTitle]="true"
  title="İptal Etme Nedeni"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="cancelPopupVisible"
>

  <div class="row">

    <div class="col-12 mb-2 mb-md-3">
      <div class="label w-100">İptal Etme Nedeni Seçiniz*</div>
      <dx-select-box
      [items]="allOrderCancelReasons"
      placeholder="İptal Nedeni Seçiniz"
      displayExpr="name"
      valueExpr="id"
      (onValueChanged)="onReasonChange($event)"

    ></dx-select-box>
    </div>

    <div class="col-12 mb-2 mb-md-3">
      <div [hidden]="isOtherCancelReason" class="label w-100">İptal Etme Nedeni*</div>
      <dx-text-box
        stylingMode="outlined"
        [(value)]="selectedOrderForCancel.note"
        [showClearButton]="true"
        [(visible)]="isOtherCancelReason"
      ></dx-text-box>
    </div>
  </div>

  <div class="popup-footer w-100">
    <dx-button
      text="Kaydet"
      type="success"
      [width]="'100%'"

      stylingMode="contained"
      (onClick)="cancelOrder()">
    </dx-button>
  </div>
</dx-popup>

