<app-page-header
  [titleText]="'Şifre Değiştirme'"
></app-page-header>

<div class="container">
  <div class="d-flex w-100 mb-3">
    <dx-text-box
      [(value)]="oldPassword"
      placeholder="Mevcut şifrenizi giriniz"
      width="250px"
      mode="password"
    ></dx-text-box>
  </div>
  <div class="d-flex w-100 mb-3">
    <dx-text-box
      [(value)]="newPassword"
      placeholder="Yeni şifrenizi giriniz"
      width="250px"
      mode="password"
    ></dx-text-box>
  </div>
  <div class="d-flex w-100 mb-3">
    <dx-text-box
      [(value)]="againNewPassword"
      placeholder="Yeni şifrenizi tekrar giriniz"
      width="250px"
      [mode]="'password'"
    ></dx-text-box>
  </div>
  <div class="d-flex w-100 mb-3">
    <dx-button
      text="Parola Değiştir"
      type="success"
      [disabled]="loading"
      (onClick)="passwordChange()"
      class="ml-2"
    ></dx-button>
  </div>
</div>