import { Component, OnInit, ViewChild } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { environment } from 'src/environments/environment'; 
import { DxFileUploaderComponent } from "devextreme-angular/ui/file-uploader";

@Component({
  selector: "app-product-categories",
  templateUrl: `./product-categories.component.html`,
  styleUrls: ["./product-categories.component.scss"],
})
export class ProductCategoriesComponent implements OnInit {
  public environment = environment;
  dataSource: any[] = [];
  image: File[] = [];

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  itemCategory: ItemCategoryRequestDto = new ItemCategoryRequestDto();
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;


  get isFormValid(): boolean {
    return this.itemCategory.name && this.itemCategory.name.length > 0 && this.itemCategory.status != null;
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getCaegories();
  }

  getCaegories() {
    this.httpService
      .get(`ItemCategory`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        res.forEach(element => {
            element.description = !element.description ? "-": element.description;
        });

        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`ItemCategory/${id}`).subscribe(res => {
      this.itemCategory = res;
      this.openPopup();
    })
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`ItemCategory/${id}`).subscribe(res => {
      this.getCaegories();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.itemCategory.id) {
      this.httpService.put('ItemCategory', this.itemCategory).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('ItemCategory', this.itemCategory).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.itemCategory = new ItemCategoryRequestDto();
    this.fileUploader.instance.reset()
  }.bind(this);

  onFileSelected(e: any): void {
    const file = e.value && e.value.length > 0 ? e.value[0] : null;
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = () => {
      const base64Url = reader.result as string;
      const index = base64Url.indexOf('base64,') + 'base64,'.length;
      this.itemCategory.base64Content = base64Url.substring(index);
      this.itemCategory.fileName = file.name;
      this.itemCategory.fileType = file.type;
    };
    reader.readAsDataURL(file);
  }

}
