
export enum OrderType {
    /**Mekan Kuryesi */
    Delivery,//Mekan kuryesi

    /**Gel Al */
    TakeAway,// Gel Al

    /**Servis Sat��� */
    Pos,// Servis sat���

    /**Provider Kuryesi, Getir Getirsin */
    Handover //provider kuryesi// Getir getirsin
}
