import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tryFormat'
})
export class TryFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) return '';
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }
}