import { Status } from "./status.enum";

export class TaxResponseDto {
    id!: string; // Verginin benzersiz kimliği
    name!: string; // Vergi adı
    code!: string; // Vergi kodu
    rate!: number; // Vergi oranı
    type!: RateType 
    status: Status | null = Status.Active
    deletedAt?: Date; // Silinme tarihi (opsiyonel)
  }
  export class TaxRequestDto {
    id?: string; // Güncelleme için gerekli
    name!: string; // Vergi adı (zorunlu)
    code!: string; // Vergi kodu (zorunlu)
    rate!: number; // Vergi oranı (zorunlu)
    type!: RateType 
    status: Status | null = Status.Active
  }
  
  export enum RateType {
    Percentage = 0, // Yüzde
    Fixed = 1       // Sabit
  }

// Lookup (UI'da gösterim için):
export const RateTypeUtils = {
    RateNamesLookup: [
      { id: RateType.Percentage, name: "Yüzde" },
      { id: RateType.Fixed, name: "Sabit" }
    ]
  };
 
