import { Status } from "./status.enum";

export class OrderCancelReasonResourceDto {
    id: string | null;
    name: string;
    status: Status;
}




export class OrderCancelReasonRequestDto {
    id: string | null;
    name: string;
    status: Status;
}