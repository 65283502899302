import { Status } from "./status.enum";

export class UserResponseDto {
    id!: string;
    role:string;
    name!: string;
    mail!: string;
    phone!: string;
    status: Status | null = Status.Active
    deletedAt?: Date;
  }
  export class UserRequestDto {
    id!:string;
    role!:Role;
    name!: string; 
    eMail!: string;
    phone!: string;
    password!:string;
    status: Status | null = Status.Active
  }
  export enum Role {
    Admin = 0,
    User = 1,
    Table = 2,

  }
// Lookup (UI'da gösterim için):
export const RoleUtils = {
  RolesLookup: [
      { id: Role.Admin, name: "Admin" },
      { id: Role.User, name: "Çalışan" }
    ]
  };

