<app-page-header
  [titleText]="'Ürünler'"
  [hasAddButton]="true"
  (onAddClicked)="openPopup()"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column
    dataField="Resim"
    caption="#"
    [width]="80"
    [allowGrouping]="false"
    dataField="thumb"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="imageTemplate"
  >
  </dxi-column>

  <dxi-column dataField="name" caption="Ürün"> </dxi-column>
  <dxi-column dataField="categoryName" caption="Kategori"> </dxi-column>
  <dxi-column dataField="price" caption="Fiyat"> </dxi-column>

  <dxi-column
    dataField="status"
    caption="Durum"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    *ngFor="let provider of providerNamesArray"
    dataField="providerMappings[{{ providerNames[provider] }}]"
    [caption]="provider"
    [width]="80"
    [allowGrouping]="false"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="providerTemplate"
  >
  </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="300"
    [allowGrouping]="false"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>

  <div *dxTemplate="let data of 'providerTemplate'">
    <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
    </dx-button>
  </div>

  <div *dxTemplate="let data of 'imageTemplate'">
    <img
      class="placeholder-img mx-2"
      [src]="data.value ? environment.fileEndpoint + data.value : 'assets/placeholder.png'"
      alt=""
    />
  </div>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        [id]="'view-btn-' + data.value"
        icon="search"
        class="mr-2"
        text="Görüntüle"
        (onClick)="onViewClicked(data.value)"
        type="success"
        [width]="120"
      >
      </dx-button>
      <dx-button
      [id]="'edit-btn-' + data.value"
      class="mr-2"
        icon="edit"
        text="Düzenle"
        type="default"
        (onClick)="onEditClicked(data.value)"
        [width]="120"
      >
      </dx-button>

      <dx-button
      [id]="'delete-btn-' + data.value"
      stylingMode="outlined"
        icon="trash"
        text="Sil"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#delete-btn-' + data.value"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">Sil</div>
      </dx-tooltip>
      <dx-tooltip
      [target]="'#view-btn-' + data.value"
      showEvent="mouseenter"
      hideEvent="mouseleave"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data = data; of: 'content'">Görüntüle / Detay Düzenle</div>
    </dx-tooltip>
    <dx-tooltip
    [target]="'#edit-btn-' + data.value"
    showEvent="mouseenter"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">Düzenle</div>
  </dx-tooltip>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Ürün"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      disabled: !isFormValid,
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="row">
        <div class="col-12">
          <div class="label w-100">Görsel</div>
        </div>
        <div class="col-md-6 mb-2 mb-md-3">
          <dx-file-uploader
            #fileUploader
            [multiple]="false"
            accept="image/*"
            uploadMode="instantly"
            (onValueChanged)="onFileSelected($event)"
          ></dx-file-uploader>
        </div>
        <div class="col-md-6 mb-2 mb-md-3 d-flex justify-content-center">
          <img
            class="placeholder-img align-self-center"
            [src]="'assets/placeholder.png'"
            alt=""
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">İsim*</div>
          <dx-text-box
            stylingMode="outlined"
            [(value)]="itemCategory.name"
            [showClearButton]="true"
          ></dx-text-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Fiyat*</div>
          <dx-number-box
            stylingMode="outlined"
            [(value)]="itemCategory.price"
            [showClearButton]="true"
          ></dx-number-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Kategori*</div>
          <dx-select-box
            [dataSource]="categoryDataSource"
            [(value)]="itemCategory.itemCategoryId"
            [searchEnabled]="true"
            valueExpr="id"
            stylingMode="outlined"
            displayExpr="name"
            searchExpr="name"
            [showClearButton]="true"
          >
          </dx-select-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Öne Çıkan*</div>
          <dx-select-box
            [dataSource]="[
              { value: true, key: 'Evet' },
              { value: false, key: 'Hayır' }
            ]"
            [(value)]="itemCategory.isFeatured"
            valueExpr="value"
            stylingMode="outlined"
            displayExpr="key"
            [showClearButton]="true"
          >
          </dx-select-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Durum*</div>
          <dx-select-box
            [dataSource]="lookupStatusWithoutDelete"
            [(value)]="itemCategory.status"
            valueExpr="id"
            stylingMode="outlined"
            displayExpr="name"
            [showClearButton]="true"
          >
          </dx-select-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Vergi</div>
          <dx-select-box
            [dataSource]="taxDataSource"
            [(value)]="itemCategory.taxId"
            valueExpr="id"
            stylingMode="outlined"
            displayExpr="name"
            [showClearButton]="true"
          >
          </dx-select-box>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Açıklama</div>
          <dx-text-area
            stylingMode="outlined"
            [(value)]="itemCategory.description"
          ></dx-text-area>
        </div>

        <div class="col-12 mb-2 mb-md-3">
          <div class="label w-100">Uyarı</div>
          <dx-text-area
            stylingMode="outlined"
            [(value)]="itemCategory.caution"
          ></dx-text-area>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
