<div id="receipt" class="">
  <div class="modal-dialog max-w-[340px] rounded-none" id="print">
    <div class="modal-body">
      <div class="text-center pb-3.5 border-b border-dashed border-gray-400">
        <h3 class="text-2xl font-bold mb-1">{{ order.restaurant.name }}</h3>
        <h4 class="text-sm font-normal">{{ order.restaurant.address }}</h4>
        <h5 class="text-sm font-normal">Tel: {{ order.restaurant.phone }}</h5>
      </div>



      <table class="w-full my-1.5">
        <tr *ngIf="hasNote()">
            <td class="text-left text-heading font-weight-bold">
              DİKKAT!  MÜŞTERİ NOTU VAR
            </td>
          </tr>
        <tr>
          <td class="text-xs text-left py-0.5 text-heading">
            Sipariş #{{ order.orderSerialNo }}
          </td>
        </tr>
        <tr>
          <td class="text-xs text-left py-0.5 text-heading">
            {{
              order.orderDatetime
                | convertUtcToLocal
                | date : "dd.MM.yyyy HH:mm"
            }}
          </td>
        </tr>
      </table>

      <table class="w-full">
        <thead class="border-t border-b border-dashed border-gray-400">
          <tr>
            <th
              scope="col"
              class="py-1 font-normal text-xs capitalize text-left text-heading w-8"
            >
              Adet
            </th>
            <th
              scope="col"
              class="py-1 font-normal text-xs capitalize flex items-center justify-between text-heading"
            >
              <span>Ürün Açıklaması</span>
              <span>Fiyat</span>
            </th>
          </tr>
        </thead>

        <tbody class="border-b border-dashed border-gray-400">
          <tr *ngFor="let item of order.orderItems" :key="item.id">
            <td class="text-left font-normal align-top py-1">
              <p class="text-xs leading-5 text-heading">{{ item.quantity }}</p>
            </td>
            <td class="text-left font-normal align-top py-1">
              <div class="flex items-center justify-between">
                <h4 class="text-sm font-normal capitalize font-weight-bold">
                  {{ item.itemName }}
                </h4>
                <p class="text-xs leading-5 text-heading">
                  {{ item.price | number : "1.2-2" }}TL
                </p>
              </div>

              <div
                *ngIf="item.itemVariations.length > 0"
                class="text-xs leading-5 font-normal text-heading max-w-[200px]"
              >
                <ng-container
                *ngTemplateOutlet="itemVaraitionTemplate; context: { list: item.itemVariations }"
              ></ng-container>
              </div>

              <ng-template #itemVaraitionTemplate let-list="list">
                <div *ngFor="let variation of list; let index">
                  {{ variation.itemAttributeName }}: {{ variation.name }} {{variation.price | number : "1.2-2" }}TL

                  <div  *ngIf="variation.basicItemVariations.length > 0" class="ml-3">
                    <ng-container *ngTemplateOutlet="itemVaraitionTemplate; context:{ list: variation.basicItemVariations }"></ng-container>
                  </div>
                </div>
              </ng-template>

              <p
                *ngIf="item.instruction"
                class="text-xs leading-5 font-normal text-heading max-w-[200px] font-weight-bold"
              >
                Not: {{ item.instruction }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="py-2 pl-7">
        <table class="w-full">
          <tr>
            <td class="text-xs text-left py-0.5 uppercase text-heading">
              Ara Toplam:
            </td>
            <td class="text-xs text-right py-0.5 text-heading">
              {{ order.subtotalPrice | number : "1.2-2" }}TL
            </td>
          </tr>
          <tr>
            <td class="text-xs text-left py-0.5 uppercase text-heading">
              İndirim:
            </td>
            <td class="text-xs text-right py-0.5 text-heading">
              -{{ order.discountPrice | number : "1.2-2" }}TL
            </td>
          </tr>
          <tr>
            <td class="text-xs text-left py-0.5 uppercase text-heading">
              Toplam:
            </td>
            <td class="text-xs text-right py-0.5 text-heading">
              {{ order.totalPrice | number : "1.2-2" }}TL
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="order.note" class="text-center pt-2 pb-4">
        <p
          class="text-[11px] leading-[14px] capitalize text-heading font-weight-bold"
        >
          Müşteri Notu: {{ order.note }}
        </p>
      </div>

      <div
        class="text-xs py-1 border-t border-b border-dashed border-gray-400 text-heading"
      >
        <table>
          <tbody>
            <tr>
              <td class="pt-1 pb-1 pr-1 font-weight-bold">Sipariş Tipi:</td>
              <td *ngIf="order.orderType != 2" class="pt-1 pb-1 font-weight-bold">{{order.orderType == 0 ? 'Restoran Kuryesi':  order.orderTyoe == 1 ? 'Gel Al' : 'Getir Kuryesi'}}</td>
              <td *ngIf="order.orderType == 2" class="pt-1 pb-1 font-weight-bold">POS</td>
            </tr>
            <tr>
              <td class="pt-1 pb-1 pr-1 font-weight-bold">Ödeme Yöntemi:</td>
              <td class="pt-1 pb-1 font-weight-bold font-weight-bold">
                {{ order.paymentMethodName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="text-xs py-1 border-b border-dashed border-gray-400 text-heading"
      >
        <table>
          <tbody>
            <tr *ngIf="order?.confirmationId ">
              <td class="pt-1 pb-1 pr-1">Doğrulama Kodu:</td>
              <td class="pt-1 pb-1">{{ order.confirmationId}}</td>
            </tr>
            <tr>
              <td class="pt-1 pb-1 pr-1">Müşteri:</td>
              <td class="pt-1 pb-1">{{ order.orderAddress?.clientFullName }}</td>
            </tr>
            <tr>
              <td class="pt-1 pb-1 pr-1">Tel:</td>
              <td class="pt-1 pb-1">
                {{ order.orderAddress?.clientPhoneNumber }}
              </td>
            </tr>
            <tr *ngIf="order.orderAddress">
              <td class="pt-1 pb-1 pr-1">Destek Hattı:</td>
              <td class="pt-1 pb-1">
                {{ order.orderAddress?.supportPhoneNumber }}
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1 pr-1">Adres:</td>
              <td class="pt-1 pb-1">
                {{ order.orderAddress?.address }} ,
                {{ order.orderAddress?.apartment }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="order.provider == 0" class="modal-header justify-content-center">
        <img style="width: 150px; height: auto;" src="assets/getir-black.png" alt="">
      </div>

      <div class="text-center pt-2 pb-4">
        <p
          class="text-[11px] leading-[14px] capitalize text-heading font-weight-bold"
        >
          Siparişiniz için Teşekkür Ederiz
        </p>
        <p
        class="text-[8px] font-normal w-[46px] leading-[10px]"
        style="font-size: small"
      >
        www.siparisentegrasyon.com
        <br />
        www.posyazilimi.com
      </p>
      </div>
    </div>
  </div>
</div>
