import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, type OnInit } from '@angular/core';
import OrderStatusUtils, { OrderStatus } from 'src/app/shared/models/order-status.enum';
import PaymentMethodUtils from 'src/app/shared/models/PaymentMethod';
import { ElapsedMinutesPipe } from 'src/app/shared/pipes/calculate-time.pipe';

@Component({
    selector: 'app-order-table',
    templateUrl: './order-table.component.html',
    styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit {
    @Output() onUpdateOrderClicked = new EventEmitter<any>();
    @Output() onCancelOrderClicked = new EventEmitter<any>();
    @Input() dataSource: any[] = []
    lookupStatus = OrderStatusUtils.StatusNamesLookup;
    paymentMethodNamesLookup = PaymentMethodUtils.PaymentMethodNamesLookup;
    constructor() {
    }

    ngOnInit(): void { }

    toggleCollapse(id) {
        document.getElementById('summary-' + id).classList.toggle('show');
    }

    getSummaryText(id) { 
        return document.getElementById('summary-' + id)?.classList?.contains('show') ? 'Gizle': 'Göster';
    }

    getNextStatusText(status, order) {   
        if (status == OrderStatus.OrderPrepared && order.orderType == 3) {
            return 'Kuryeye teslim edildi';
          }
      
          if (status == OrderStatus.CourierOnTheWay && order.orderType == 3) {
            return null;
          }
           
        return OrderStatusUtils.GetNextStatusText(status);
    }

    updateOrder(data) {
        this.onUpdateOrderClicked.emit(data);
    }

    cancelOrder(data) {
        this.onCancelOrderClicked.emit(data);
    }

}
