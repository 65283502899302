import { Component, OnInit } from "@angular/core";
import StatusUtils from "src/app/shared/models/status.enum"; // Status lookup bilgisi
import { HttpService } from "src/app/shared/services/http.service"; // API çağrıları için servis
import { confirm } from "devextreme/ui/dialog"; // Silme işlemi için onay popup'ı
import { RateTypeUtils, TaxRequestDto } from "src/app/shared/models/tax.model";

@Component({
  selector: "app-tax-list",
  templateUrl: `./tax-list.component.html`,
  styleUrls: ["./tax-list.component.scss"],
})
export class TaxComponent implements OnInit {
  dataSource: any[] = []; // Vergi verilerinin tutulduğu liste
  lookupStatus = StatusUtils.StatusNamesLookup; // Durum gösterimi için lookup

  isPopupVisible: boolean = false; // Popup görünürlük kontrolü
  taxRequestDto: TaxRequestDto = new TaxRequestDto(); // Formdaki veri için DTO
  rateTypeUtils =  RateTypeUtils;
  get isFormValid(): boolean {
    // Form geçerlilik kontrolü
    return (
      this.taxRequestDto.name &&
      this.taxRequestDto.name.length > 0 &&
      this.taxRequestDto.rate > 0 &&
      this.taxRequestDto.type !== undefined
    );
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getTaxes();
  }

  getTaxes() {
    this.httpService
      .get(`Tax`, { params: StatusUtils.GetStatusParams() }) // API çağrısı
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.taxRequestDto = new TaxRequestDto(); // Formu temizle
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`Tax/${id}`).subscribe((res) => {
      this.taxRequestDto = res;
      this.isPopupVisible = true;
    });
  }

  async onDeleteClicked(id: string) {
    let result = await confirm(
      "Vergiyi silmek istediğinizden emin misiniz?",
      "Silme Onayı"
    );
    if (!result) {
      return;
    }

    this.httpService.delete(`Tax/${id}`).subscribe(() => {
      this.getTaxes();
    });
  }

  onSubmitClicked = function () {
    if (!this.isFormValid) {
      return;
    }

    if (this.taxRequestDto.id) {
      // Güncelleme
      this.httpService.put("Tax", this.taxRequestDto).subscribe(() => {
        this.getTaxes();
        this.onCloseClicked();
      });
    } else {
      // Yeni ekleme
      this.httpService.post("Tax", this.taxRequestDto).subscribe(() => {
        this.getTaxes();
        this.onCloseClicked();
      });
    }
  }.bind(this);

  onCloseClicked = function () {
    this.isPopupVisible = false;
    this.taxRequestDto = new TaxRequestDto(); // Formu temizle
  }.bind(this);
}
