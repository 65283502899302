import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DxAccordionModule, DxButtonModule, DxChartModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxFileUploaderModule, DxFormModule, DxListModule, DxNumberBoxModule, DxPieChartModule, DxPivotGridModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxSwitchModule, DxTabPanelModule, DxTabsModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxTooltipModule, DxTreeMapModule } from 'devextreme-angular';
import { DxDateRangeBoxModule } from 'devextreme-angular';
import { PointOfSaleComponent } from './pages/point-of-sale/point-of-sale.component';
import { ProductDetailComponent } from './pages/products/product-detail/product-detail.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductCategoriesComponent } from './pages/products/product-categories/product-categories.component';
import { ProductAttributesComponent } from './pages/products/product-attributes/product-attributes.component';
import { PageHeaderComponent } from "./shared/components/page-header/page-header.component";
import { StatusBadgeComponent } from './shared/components/status-badge/status-badge.component';
import { CommonModule } from '@angular/common';
import { OrderListComponent } from './pages/orders/order-list/order-list.component';
import { OrderDetailComponent } from './pages/orders/order-detail/order-detail.component';
import { OrderTableComponent } from './pages/orders/order-table/order-table.component';
import { ElapsedMinutesPipe } from './shared/pipes/calculate-time.pipe';
import { ConvertUtcToLocalPipe } from './shared/pipes/convert-utc-to-local.pipe';
import { OrderPrintComponent } from './pages/orders/order-print/order-print.component';
import { TaxComponent } from './pages/orders/taxes/tax-list.component';
import { UserListComponent } from './pages/user-management/user-list/user-list.component';
import { TableComponent } from './pages/orders/table/table.component';
import { PasswordChangeComponent } from './pages/user-management/password-change/password-change.component';
import { EndOfDayReportComponent } from './pages/reports/report-end-of-day/end-of-day-report.component';
import { FilterByPropPipe } from './shared/pipes/filterByProp.pipe';
import { ProductExtraCopyComponent } from './pages/products/product-extra-copy/product-extra-copy.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TryFormatPipe } from './shared/pipes/try-format.pipe';
import { CancelReasonComponent } from './pages/orders/cancel-reason/cancel-reason';

const routes: Routes = [
  // {
  //   path: 'tasks',
  //   component: TasksComponent,
  //   canActivate: [ AuthGuardService ]
  // },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pos',
    component: PointOfSaleComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'orders',
    component: OrderListComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'cancel-reasons',
    component: CancelReasonComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'orders/:orderId',
    component: OrderDetailComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'taxes',
    component: TaxComponent,
    canActivate: [ AuthGuardService ]
  },{
    path: 'tables',
    component: TableComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'products',
    component: ProductListComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'products/categories',
    component: ProductCategoriesComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'products/attributes',
    component: ProductAttributesComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'products/detail/:itemId',
    component: ProductDetailComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'products/extra-copy',
    component: ProductExtraCopyComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'users/user-list',
    component: UserListComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'users/password-change',
    component: PasswordChangeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },

  {
    path: 'reports/end-of-day-report',
    component: EndOfDayReportComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
    providers: [AuthGuardService, ConvertUtcToLocalPipe, ElapsedMinutesPipe],
    exports: [RouterModule],
    declarations: [
        HomeComponent,
        ProfileComponent,
        PointOfSaleComponent,
        ProductDetailComponent,
        ProductListComponent,
        ProductCategoriesComponent,
        ProductAttributesComponent,
        OrderListComponent,
        OrderDetailComponent,
        OrderTableComponent,
        OrderPrintComponent,
        ElapsedMinutesPipe,
        ConvertUtcToLocalPipe,
        FilterByPropPipe,
        TaxComponent,
        UserListComponent,
        TableComponent,
        PasswordChangeComponent,
        EndOfDayReportComponent,
        ProductExtraCopyComponent,
        TryFormatPipe,
        CancelReasonComponent
    ],
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
        CommonModule,
        DxDataGridModule,
        DxFormModule,
        DxTooltipModule,
        DxTabsModule,
        DxScrollViewModule,
        DxDateBoxModule,
        DxTextBoxModule,
        DxRadioGroupModule,
        DxListModule,
        DxAccordionModule,
        NgApexchartsModule,
        DxCheckBoxModule,
        DxTabPanelModule,
        DxTextAreaModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxSwitchModule,
        DxTreeMapModule,
        DxFileUploaderModule,
        DxDateRangeBoxModule,
        DxChartModule,
        DxPieChartModule,
        DxPopupModule,
        DxButtonModule,
        PageHeaderComponent,
        StatusBadgeComponent,
        DxTagBoxModule
    ]
})
export class AppRoutingModule { }
