import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, type OnInit } from '@angular/core';
import { DxButtonModule, DxToolbarModule } from 'devextreme-angular';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [
        CommonModule,
        DxToolbarModule,
        DxButtonModule
    ],
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit {
    @Input() titleText: string;
    @Input() hasAddButton: boolean;
    @Output() onAddClicked: EventEmitter<any> = new EventEmitter();
    @ContentChild('customTemplate') customTemplate!: TemplateRef<any>;

    ngOnInit(): void { }


    onAddClick() {
        this.onAddClicked.emit(true);
    }

}