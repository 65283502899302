import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from "devextreme/ui/dialog";
import { ItemRequest } from "src/app/shared/models/item.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DxFileUploaderComponent } from "devextreme-angular/ui/file-uploader";
import notify from "devextreme/ui/notify";

@Component({
  selector: "app-product-extra-copy",
  templateUrl: `./product-extra-copy.component.html`,
  styleUrls: ["./product-extra-copy.component.scss"],
})
export class ProductExtraCopyComponent implements OnInit {
  private _selectedTargetItemId: string;

  @Input()
  get selectedTargetItemId(): string {
    return this._selectedTargetItemId;
  }
  set selectedTargetItemId(value: string) {
    this._selectedTargetItemId = value;
    this.setSelectedTargetItemFromInput();
  }

  public environment = environment;
  sourceItemList: any[] = [];
  sourceItemExtraList: any[] = [];
  selectedSourceItemId: any;
  selectedSourceItemExtraIds: any[] = [];
  targetItemList: any[] = [];
  selectedTargetItems: any[] = [];
  image: string | null = null;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;
  @Output() onSaved: EventEmitter<any> = new EventEmitter();

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit(): void {
    this.getSourceItems();
  }

  getSourceItems() {
    this.httpService
      .get(`Item`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        res.forEach((element) => {
          element.description = !element.description
            ? "-"
            : element.description;
        });

        this.sourceItemList = res;
      });
  }

  getSourceItem(itemId: any) {
    this.httpService
      .get(`Item/${itemId}`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.sourceItemExtraList = res.extras;
      });
  }

  onSourceItemValueChanged(data) {
    this.selectedSourceItemId = data.value;
    this.getSourceItem(data.value);
    this.getTargetItems();
  }

  onSourceItemExtraValueChanged(data) {
    this.selectedSourceItemExtraIds = data.value;
  }

  getTargetItems() {
    this.httpService
      .get(`Item`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        res.forEach((element) => {
          element.description = !element.description
            ? "-"
            : element.description;
        });
        this.targetItemList = res.filter(
          (f) => f.id != this.selectedSourceItemId
        );
      });
  }

  setSelectedTargetItemFromInput() {
    this.selectedTargetItems = [this.selectedTargetItemId];    
  }

  saveExtra() {
    if (
      this.selectedSourceItemExtraIds.length == 0 ||
      this.selectedTargetItems.length == 0
    ) {
      notify(
        {
          message:
            "En az 1 tane kaynak ürün ekstra ve en az 1 tane de hedef ürün seçmelisiniz.",
          width: 450,
        },
        "error",
        2000
      );
      return;
    }

    var requestDto = {
      ids: this.selectedSourceItemExtraIds,
      itemIds: this.selectedTargetItems,
    };
    this.httpService.post(`ItemExtra/copy`, requestDto).subscribe((res) => {
      this.clear();
      this.onSaved.emit(true);
    });
  }

  clear() {
    this.selectedSourceItemExtraIds = [];
    this.selectedTargetItems = [];
  }
}
