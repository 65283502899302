import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProp',
  pure: true
})
export class FilterByPropPipe implements PipeTransform {
  transform<T>(
    items: T[] | null | undefined,
    prop: keyof T,
    value: any,
    not: boolean = false
  ): T[] {
    if (!items || !prop || value === undefined || value === null) {
      return items || [];
    }

    if (not) {
        return items.filter((item) => item[prop] != value);
    }

    return items.filter((item) => item[prop] === value);
  }
}