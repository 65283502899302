import { state } from "@angular/animations";
import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  type OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import OrderStatusUtils, {
  OrderStatus,
} from "src/app/shared/models/order-status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { firstValueFrom } from "rxjs";
import PaymentMethodUtils from "src/app/shared/models/PaymentMethod";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit {
  @Input() orderId: string;
  @Input() getOrderIdFromParams: boolean = true;
  @ViewChild("printComponent") printComponent: ElementRef;

  order: any;
  lookupStatus = OrderStatusUtils.StatusNamesLookup;

  nextStatusName: string;
  cancelReasons = [];
  selectedOrderForCancel = { id: null, cancelReasonId: null, note: null };

  cancelPopupVisible = false;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.getOrderIdFromParams) {
      this.orderId = this.route.snapshot.paramMap.get("orderId");
    }

    this.getOrder();
  }

  getOrder() {
    this.httpService.get(`Order/${this.orderId}`).subscribe((res) => {
      this.order = res;
      this.order.statusName = this.lookupStatus.find(
        (x) => x.id == this.order.status
      ).name;
      this.nextStatusName = this.getNextStatusText(this.order.status);
      this.order.paymentMethodName = this.order.paymentMethodName ?? 'Online Ödeme';
    });
  }

  getNextStatusText(status) {
    if (status == OrderStatus.OrderPrepared && this.order.orderType == 3) {
      return 'Kuryeye teslim edildi';
    }

    if (status == OrderStatus.CourierOnTheWay && this.order.orderType == 3) {
      return null;
    }

    return OrderStatusUtils.GetNextStatusText(status);
  }


  getPaymentMethodName(paymentMethod) {

    debugger;

    return PaymentMethodUtils.GetPaymentMethodName(paymentMethod);
  }

  print() {
    const printContents = this.printComponent.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }

  async updateOrderStatus(data) {
    let result = await confirm(
      `Siparişin durumunu <b>${OrderStatusUtils.GetNextStatusText(
        data.status
      )}</b> olarak güncellemek istediğinize emin misiniz??`,
      "Durum Güncelleme Onayı"
    );

    if (!result) {
      return;
    }

    this.httpService
      .put("Order", {
        orderId: data.id,
        orderStatus: OrderStatusUtils.NextOrderStatusDict[data.status],
      })
      .subscribe(
        (res) => {
          this.getOrder();
        },
        (e) => {
          notify(
            {
              message: "İşlem başarısız oldu.",
              width: 450,
            },
            "error",
            2000
          );
        }
      );
  }

  async openCancelReasonModal(order) {
    this.selectedOrderForCancel = {
      id: order.id,
      cancelReasonId: null,
      note: null,
    };
    this.cancelReasons = [];
    if (order.provider == 0) {
      const cancelReasons: any[] = await firstValueFrom(
        this.httpService.get(
          "Order/GetirCancelOptionByOrderId?orderId=" + order.id
        )
      );
      if (cancelReasons.length == 0) {
        notify(
          {
            message: "Bu sipariş iptal edilemez.",
            width: 450,
          },
          "error",
          2000
        );
      }
      this.cancelReasons = cancelReasons;
      this.selectedOrderForCancel.cancelReasonId = cancelReasons[0].id;
    }

    this.cancelPopupVisible = true;
  }

  cancelOrder = async function () {
    let result = await confirm(
      `Siparişi <b>iptal</b> etmek istediğinize emin misiniz??`,
      "Sipariş İptal Onayı"
    );

    if (!result) {
      return;
    }

    this.httpService
      .put("Order", {
        orderId: this.selectedOrderForCancel.id,
        orderStatus: OrderStatus.OrderCancelledByRestaurant,
        cancelReasonId: this.selectedOrderForCancel.cancelReasonId,
        cancelReasonText: this.selectedOrderForCancel.note,
      })
      .subscribe(
        (res) => {
          this.reload();
          this.onCloseClicked();
        },
        (e) => {
          notify(
            {
              message: "İşlem başarısız oldu.",
              width: 450,
            },
            "error",
            2000
          );
        }
      );
  }.bind(this);

  onCloseClicked = function () {
    this.cancelPopupVisible = false;
    this.selectedOrderForCancel = null;
  }.bind(this);
}
