import { Component } from "@angular/core";
import notify from "devextreme/ui/notify";
import { SalesInfoRequestDto } from "src/app/shared/models/dashboard.model";
import { HttpService } from "src/app/shared/services/http.service";
import { ApexOptions } from 'ng-apexcharts';
import * as ApexCharts from "apexcharts";
import { TryFormatPipe } from "src/app/shared/pipes/try-format.pipe";

const msInDay = 1000 * 60 * 60 * 24;
const now = new Date();
let tenDaysAgo = new Date(now.getTime() - msInDay * 10);
if (tenDaysAgo.getDate() < 1) {
  tenDaysAgo.setDate(1);
}

const initialValue: [Date, Date] = [tenDaysAgo, now];

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [TryFormatPipe]
})
export class HomeComponent {
  currentValue: any = initialValue;
  averageOrderPrice = 1000;
  tabsWithText: any[] = [
    {
      id: 0,
      text: "Günlük",
    },
    {
      id: 1,
      text: "Haftalık",
    },
    {
      id: 2,
      text: "Aylık",
    },
    {
      id: 3,
      text: "Yıllık",
    },
    {
      id: 4,
      text: "Özel",
    },
  ];

  salesInfoRequestDto: SalesInfoRequestDto = new SalesInfoRequestDto();
  tabsSelectedIndex: any = 0;
  salesInfo: any[] = [];
  saleChannels: any[] = [];
  totalSales: number = 0;
  totalOrders: number = 0;
  totalDiscount: number = 0;
  totalTax: number = 0;
  bestSellingCategories: any[] = [];
  orderTotals: any[] = [];
  topProducts: any[] = [];
  salesTrends: any[] = [];
  latestOrders: any[] = [];
  heatmapChart: ApexCharts | null = null;

  constructor(private httpService: HttpService, private tryFormatPipe: TryFormatPipe) {
    this.clearSalesInfo();
    const startDate = initialValue[0];
    const startUtcDate = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0
      )
    );
    const endDate = initialValue[1];
    const endUtcDate = new Date(
      Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        0,
        0,
        0
      )
    );

    this.salesInfoRequestDto.startDate = startUtcDate;
    this.salesInfoRequestDto.endDate = endUtcDate;
    this.onTabsSelectionChanged(false);
  }

  customizeTooltip = ({ percentText, valueText }) => ({
    text: `${percentText ?? "Toplam"} - ${valueText} TL`,
  });

  customizeTooltipCandleStick(arg) {
    return {
      text:
        `İlk Sipariş: ${arg.openValue}TL<br/>` +
        `Son Sipariş: ${arg.closeValue}TL<br/>` +
        `En Yüksek Sipariş: ${arg.highValue}TL<br/>` +
        `En Düşük Sipariş: ${arg.lowValue}TL<br/>`,
    };
  }

  customizeLabel(arg) {
    return `${arg.valueText}TL (${arg.percentText})`;
  }

  onDateChanged(data, preventApiCall = false) {
    this.clearSalesInfo();
    this.currentValue = [data.value[0], data.value[1]];

    const startDate = data.value[0];
    const startUtcDate = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0
      )
    );
    const endDate = data.value[1];
    const endUtcDate = new Date(
      Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        0,
        0,
        0
      )
    );

    const timeDiff = endUtcDate.getTime() - startUtcDate.getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay;
    const oneYear = 365 * oneDay;
    
    if (timeDiff <= oneDay) {
      this.salesInfoRequestDto.reportFormat = "Hourly";
    } else if (timeDiff <= oneWeek) {
      this.salesInfoRequestDto.reportFormat = "Daily";
    } else if (timeDiff <= oneMonth) {
      this.salesInfoRequestDto.reportFormat = "Weekly";
    } else if (timeDiff <= oneYear) {
      this.salesInfoRequestDto.reportFormat = "Monthly";
    } else {
      this.salesInfoRequestDto.reportFormat = "Yearly";
    }

    this.salesInfoRequestDto.startDate = startUtcDate;
    this.salesInfoRequestDto.endDate = endUtcDate;

    if (preventApiCall) {
      return;
    }

    this.getSalesInfo();
  }

  onTabsSelectionChanged(preventApiCall = true) {
    this.clearSalesInfo();
    const getDateRange = (index) => {
      const today = new Date();
      let startDate: Date = this.currentValue[0];
      let endDate: Date = this.currentValue[1];
      
      if (index === 0) {
        startDate = new Date(today);
        endDate = new Date(today);
      } else if (index === 1) {
        const today = new Date();
        const firstDayOfWeek = new Date(today);
        const lastDayOfWeek = new Date(today);
        const dayOfWeek = today.getDay();
        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        firstDayOfWeek.setDate(today.getDate() - daysToSubtract);
        firstDayOfWeek.setHours(0, 0, 0, 0);

        const millisecondsInADay = 24 * 60 * 60 * 1000;
        lastDayOfWeek.setTime(firstDayOfWeek.getTime() + (6 * millisecondsInADay));
        lastDayOfWeek.setHours(23, 59, 59, 999);

        startDate = new Date(firstDayOfWeek);
        endDate = new Date(lastDayOfWeek);
      } else if (index === 2) {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      } else if (index === 3) {
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today.getFullYear(), 11, 31);
      }

      return { value: [startDate, endDate] };
    };

    this.onDateChanged(getDateRange(this.tabsSelectedIndex), preventApiCall);
  }

  getSalesInfo() {
    if (
      !this.salesInfoRequestDto.startDate ||
      !this.salesInfoRequestDto.endDate ||
      isNaN(this.salesInfoRequestDto.startDate.getTime()) ||
      isNaN(this.salesInfoRequestDto.endDate.getTime())
    ) {
      return null; // Geçerli değerler yoksa validasyonu çalıştırmayın
    }

    if (this.tabsSelectedIndex == 0) {
      if (
        this.salesInfoRequestDto.startDate.getFullYear() !==
          this.salesInfoRequestDto.endDate.getFullYear() ||
        this.salesInfoRequestDto.startDate.getMonth() !==
          this.salesInfoRequestDto.endDate.getMonth()
      ) {
        notify(
          {
            message:
              "Günlük rapor formatında tarihler aynı ay içinde olmalıdır.",
            width: 450,
          },
          "error",
          2000
        );
        return;
      }
    } else if (this.tabsSelectedIndex == 1) {
      const diffInMonths =
        (this.salesInfoRequestDto.endDate.getFullYear() -
          this.salesInfoRequestDto.startDate.getFullYear()) *
          12 +
        (this.salesInfoRequestDto.endDate.getMonth() -
          this.salesInfoRequestDto.startDate.getMonth());
      if (diffInMonths > 3 || diffInMonths < 0) {
        notify(
          {
            message:
              "Haftalık rapor formatında tarihler maksimum 3 ay aralığında olmalıdır.",
            width: 450,
          },
          "error",
          2000
        );
        return;
      }
    } else if (this.tabsSelectedIndex == 2) {
      if (
        this.salesInfoRequestDto.startDate.getFullYear() !==
        this.salesInfoRequestDto.endDate.getFullYear()
      ) {
        return {
          invalidDateRange:
            "Aylık rapor formatında tarihler aynı yıl içinde olmalıdır.",
        };
      }
    }
    
    this.httpService
      .post("Dashboard/SaleInfo", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.salesInfo = res.salesResourceDto;
        this.saleChannels = res.saleChannelsResourceDto;
        this.totalSales = res.totalSales;
        this.totalOrders = res.totalOrders;
        this.totalDiscount = res.totalDiscount;
        this.totalTax = res.totalTax;
      });

      this.httpService
      .post("Dashboard/BestSellingCategories", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.bestSellingCategories = res;
      });

      this.httpService
      .post("Dashboard/OrderTotals", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.orderTotals = res;
      });

      this.httpService
      .post("Dashboard/TopProducts", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.topProducts = res;
      });

      this.httpService
      .post("Dashboard/SalesTrends", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.salesTrends = res;
      });

      this.httpService
      .post("Dashboard/LatestOrders", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.latestOrders = res;
      });

      if (this.heatmapChart) { this.heatmapChart.destroy(); }
      this.httpService
      .post("Dashboard/HeatMapDatas", this.salesInfoRequestDto)
      .subscribe((res) => {
        this.generateHeatmap(res);
      });
  }

  clearSalesInfo() {
    this.salesInfo = [];
  }

  generateHeatmap(heatMapData: any) {
    // X ve Y eksenlerini dinamik olarak belirleme
    const uniqueXAxis = [...new Set(heatMapData.map((item: any) => item.xAxis))].sort((a: any, b: any) => Number(a) - Number(b)); // X ekseni için benzersiz değerler
    const uniqueYAxis = [...new Set(heatMapData.map((item: any) => item.yAxis))]; // Y ekseni için benzersiz değerler
  
    // Veriyi Y eksenine (örneğin günler) göre gruplama
    const groupedData = uniqueYAxis.map((yValue) => {
      // Bu Y ekseni değeri için X ekseni verilerini eşleştir
      const yData = uniqueXAxis.map((xValue) => {
        const match = heatMapData.find((item: any) => item.xAxis == xValue && item.yAxis == yValue);
        return {
          x: xValue.toString(), // X ekseni değeri
          y: match ? match.value : 0, // Değer bulunamazsa 0 kabul edilir
        };
      });
  
      return {
        name: yValue, // Y ekseni adı (örneğin gün adı)
        data: yData,  // X-Y verileri
      };
    });
  
    // ApexCharts seçenekleri
    const options = {
      series: groupedData,
      chart: {
        height: 350,
        type: "heatmap",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#ff0000"],
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: "#808080", // Sıfır değeri için gri renk
                name: "Satış Yok",
              }
            ],
          },
        },
      },
      title: {
        text: "Isı Haritası",
      },
      xaxis: {
        categories: uniqueXAxis.map((x) => `${x}`), // X ekseni değerleri (örneğin saatler)
      },
      yaxis: {
        categories: uniqueYAxis, // Y ekseni değerleri
      },
    };
  
    // Chart oluşturma
    this.heatmapChart = new ApexCharts(document.querySelector("#heatmapCanvas"), options);
    this.heatmapChart.render();
  }
  
}
