<app-page-header
  [titleText]="'Vergiler'"
  [hasAddButton]="true"
  (onAddClicked)="openPopup()"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="name" caption="Ad"> </dxi-column>
  <dxi-column dataField="code" caption="Kod"> </dxi-column>
  <dxi-column dataField="rate" caption="Oran"> </dxi-column>
  <dxi-column
    dataField="type"
    caption="Tür"
    [width]="180"
    [lookup]="{ dataSource: ['Yüzde', 'Sabit'], displayExpr: 'name' }"
  >
  </dxi-column>
  <dxi-column
    dataField="status"
    caption="Durum"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="280"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>


  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        class="mr-2"
        icon="edit"
        text="Düzenle"
        type="default"
        (onClick)="onEditClicked(data.value)"
        [width]="120"
      >
      </dx-button>

      <dx-button
        stylingMode="outlined"
        icon="trash"
        text="Sil"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Vergi"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      disabled: !isFormValid,
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2">
        <div class="label w-100">Vergi Adı*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="taxRequestDto.name"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2">
        <div class="label w-100">Vergi Kodu*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="taxRequestDto.code"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2">
        <div class="label w-100">Vergi Oranı*</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="taxRequestDto.rate"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2">
        <div class="label w-100">Tür*</div>

        <dx-select-box
        [dataSource]="rateTypeUtils.RateNamesLookup"
        [(value)]="taxRequestDto.type"
        valueExpr="id"
        displayExpr="name"
        stylingMode="outlined"
        [showClearButton]="true"
      >
      </dx-select-box>

      </div>
    </div>
  </div>
</dx-popup>
