import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { ItemExtraRequestDto, ItemRequest, ItemResourceDto, ItemVariation } from "src/app/shared/models/item.model";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: "app-product-detail",
  templateUrl: `./product-detail.component.html`,
  styleUrls: ["./product-detail.component.scss"],
})
export class ProductDetailComponent implements OnInit {
  tabPanelItems: any[] = [
    {
      icon: "textdocument",
      title: "Bilgi",
      key: 0,
    },
    // {
    //   icon: "image",
    //   title: "Görsel",
    //   key: 1,
    // },
    {
      icon: "inserttable",
      title: "Varyasyon",
      key: 2,
    },
    {
      icon: "fieldchooser",
      title: "Ekstra",
      key: 3,
    },
  ];

  attributeDataSource: any[] = [];
  isExtraCopyPopupVisible = false;

  itemId: string;
  item: ItemResourceDto = new ItemResourceDto();

  productNames = StatusUtils.StatusNamesDictionary;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  itemVariation: ItemVariation = new ItemVariation();
  isVariationPopupVisible = false;
  get isVariationFormValid() {
    return this.itemVariation.name != null && this.itemVariation.name.length > 0 && this.itemVariation.price != null && this.itemVariation.status != null
  }


  itemExtra: ItemExtraRequestDto = new ItemExtraRequestDto();
  isExtraPopupVisible = false;
  get isExtraFormValid() {
    return this.itemExtra.name != null && this.itemExtra.name.length > 0 && this.itemExtra.price != null && this.itemExtra.status != null
  }

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService) {}

  ngOnInit(): void {
    this.itemId = this.activatedRoute.snapshot.params["itemId"];
    this.getItem();
    this.getAttributes();
  }

  getItem() {
    this.httpService.get(`Item/${this.itemId}`, { params: StatusUtils.GetStatusParams() }).subscribe((res) => {
      this.item = res;

      this.item.variations.forEach(variation => {
        variation.itemAttributeName = this.item.itemAttributes.find(x => x.id == variation.itemAttributeId)?.name??'-';
      });
    });
  }

  getAttributes() {
    this.httpService
      .get(`ItemAttribute`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.attributeDataSource = res;
      });
  }

  onAddVariatonClicked() {
    this.isVariationPopupVisible = true;
  }

  onVariationEditClicked(data: ItemVariation) {
    this.itemVariation = {...data};
    this.onAddVariatonClicked();
  }

  async onVariationDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`ItemVariation/${id}`).subscribe(res => {
      this.getItem();
    })
  }

  onVariationSubmitClicked = function()  {    
    if (!this.isVariationFormValid ) {
      return;
    }

    this.itemVariation.itemId = this.itemId;
    if (this.itemVariation.id) {
      this.httpService.put('ItemVariation', this.itemVariation).subscribe(res => {
        this.getItem();
        this.onVariationCloseClicked();
      })
    } else {
      this.httpService.post('ItemVariation', this.itemVariation).subscribe(res => {
        this.getItem();
        this.onVariationCloseClicked();
      })
    }

  }.bind(this);

  onVariationCloseClicked = function() {    
    this.isVariationPopupVisible = false;
    this.itemVariation = new ItemVariation();
  }.bind(this);


  onAddExtraClicked() {
    this.isExtraPopupVisible = true;
  }

  onExtraEditClicked(data: ItemExtraRequestDto) {
    this.itemExtra = {...data};
    this.onAddExtraClicked();
  }

  async onExtraDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`ItemExtra/${id}`).subscribe(res => {
      this.getItem();
    })
  }

  onExtraSubmitClicked = function()  {    
    if (!this.isExtraFormValid ) {
      return;
    }

    this.itemExtra.itemId = this.itemId;
    if (this.itemExtra.id) {
      this.httpService.put('ItemExtra', this.itemExtra).subscribe(res => {
        this.getItem();
        this.onExtraCloseClicked();
      })
    } else {
      this.httpService.post('ItemExtra', this.itemExtra).subscribe(res => {
        this.getItem();
        this.onExtraCloseClicked();
      })
    }

  }.bind(this);

  onExtraCloseClicked = function() {    
    this.isExtraPopupVisible = false;
    this.itemExtra = new ItemExtraRequestDto();
  }.bind(this);

}
