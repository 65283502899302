import { Component, OnInit } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { TableRequestDto } from "src/app/shared/models/table.model";

@Component({
  selector: "app-table",
  templateUrl: `./table.component.html`,
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  dataSource: any[] = [];


  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  tableRequestDto: TableRequestDto = new TableRequestDto();


  get isFormValid(): boolean {
    return this.tableRequestDto.name && this.tableRequestDto.name.length > 0 ;
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getTables();
  }

  getTables() {
    this.httpService
      .get(`Table`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.tableRequestDto = new TableRequestDto();
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`Table/${id}`).subscribe(res => {
      this.tableRequestDto = res;
      this.isPopupVisible = true;
    })
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`Table/${id}`).subscribe(res => {
      this.getTables();
    })
  }



  onInit = function(e:any)  {  
    debugger;
    e.component.registerKeyHandler("escape", function (arg) {  
        arg.stopPropagation();  
    });  
}  

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.tableRequestDto.id) {
      this.httpService.put('Table', this.tableRequestDto).subscribe(res => {
        this.getTables();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Table', this.tableRequestDto).subscribe(res => {
        this.getTables();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.tableRequestDto = new TableRequestDto();
  }.bind(this);

}
