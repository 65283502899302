import { Component, OnInit, ViewChild } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { ItemRequest } from "src/app/shared/models/item.model";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment'; 
import { DxFileUploaderComponent } from "devextreme-angular/ui/file-uploader";

@Component({
    selector: 'app-product-list',
    templateUrl: `./product-list.component.html`,
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  public environment = environment;
  dataSource: any[] = [];

  categoryDataSource: any[] = [];
  taxDataSource: any[] = [];

  image: string | null = null;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  itemCategory: ItemRequest = new ItemRequest();
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;

  get isFormValid(): boolean {
    return this.itemCategory.name 
    && this.itemCategory.name.length > 0 
    && this.itemCategory.status != null
    && this.itemCategory.itemCategoryId != null
    && this.itemCategory.price != null
    && this.itemCategory.isFeatured != null
  }

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit(): void {
    this.getItems();
    this.getCaegories();
    this.getTaxes();
  }
  getTaxes() {
    this.httpService
    .get(`Tax`, { params: StatusUtils.GetStatusParams() })
    .subscribe((res) => {
      this.taxDataSource = res;
    });
  }


  getItems() {
    this.httpService
      .get(`Item`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        res.forEach(element => {
            element.description = !element.description ? "-": element.description;
        });

        this.dataSource = res;
      });
  }

  getCaegories() {
    this.httpService
      .get(`ItemCategory`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.categoryDataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`Item/${id}`).subscribe(res => {
      this.itemCategory = res;
      this.openPopup();
    })
  }

  onViewClicked(id: string) {
    this.router.navigate([`/products/detail/${id}`]);
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`Item/${id}`).subscribe(res => {
      this.getItems();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }
    if (this.itemCategory.id) {
      this.httpService.put('Item', this.itemCategory).subscribe(res => {
        this.getItems();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Item', this.itemCategory).subscribe(res => {
        this.getItems();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.itemCategory = new ItemRequest();
    this.fileUploader.option("value", []);
  }.bind(this);

  onValueChanged(e) {
    const formData = new FormData();
    formData.append('file', e.value[0]);
    //this.itemCategory.image = formData;
}

onFileSelected(e: any): void {
  const file = e.value && e.value.length > 0 ? e.value[0] : null;
  if (!file) return;

  const reader = new FileReader();
  reader.onload = () => {
    const base64Url = reader.result as string;
    const index = base64Url.indexOf('base64,') + 'base64,'.length;
    this.itemCategory.base64Content = base64Url.substring(index);
    this.itemCategory.fileName = file.name;
    this.itemCategory.fileType = file.type;
  };
  reader.readAsDataURL(file);
}

}
