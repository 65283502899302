import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.message || 'Bir hata oluştu. Lütfen tekrar deneyiniz.';
        this.showErrorNotification(errorMessage);
        return throwError(error);
      })
    );
  }

  private showErrorNotification(message: string): void {
    notify(
      {
        message: message,
        type: 'error',
        displayTime: 4000,
        position: {
          my: 'center top',
          at: 'center top',
        },
      },
      'error',
      4000
    );
  }
}