<app-page-header [titleText]="'Extra Kopyalama'"></app-page-header>

<div class="container-fluid">
  <div class="row py-2">
    <div class="col-12 d-flex justify-items-between align-items-end">
      <div class="db-list-item w-100">
        <span class="db-list-item-title">Kaynak Ürün</span>
        <dx-select-box
          [dataSource]="sourceItemList"
          displayExpr="name"
          valueExpr="id"
          [inputAttr]="{ 'aria-label': 'Kaynak Ürün' }"
          searchMode="contains"
          searchExpr="name"
          stylingMode="outlined"
          minSearchLength="0"
          [searchEnabled]="true"
          placeholder="Kaynak Ürün"
          (onValueChanged)="onSourceItemValueChanged($event)"
        ></dx-select-box>
      </div>

      <dx-button
        stylingMode="contained"
        text="Kaydet"
        type="success"
        class="ml-2"
        [width]="120"
        height="48"
        [disabled]="
          !selectedSourceItemId ||
          !selectedSourceItemExtraIds.length ||
          !selectedTargetItems.length
        "
        (onClick)="saveExtra()"
      >
      </dx-button>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-6" [ngClass]="{ 'col-md-12': !!selectedTargetItemId }">
      <div class="label">Ekstralar</div>
      <dx-data-grid
        class="dx-card wide-card"
        [dataSource]="sourceItemExtraList"
        keyExpr="id"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [(selectedRowKeys)]="selectedSourceItemExtraIds"
      >
        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-selection
          selectAllMode="allPages"
          showCheckBoxesMode="always"
          mode="multiple"
        ></dxo-selection>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxi-column dataField="name" caption="İsim"> </dxi-column>
        <dxi-column dataField="price" caption="Ek Fiyat"> </dxi-column>

        <dxi-column
          dataField="status"
          caption="Durum"
          [width]="180"
          cellTemplate="statusTemplate"
        >
          <dxo-lookup
            [dataSource]="lookupStatus"
            valueExpr="id"
            displayExpr="name"
          ></dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'statusTemplate'">
          <app-status-badge [status]="data.value"></app-status-badge>
        </div>

        <div *dxTemplate="let data of 'providerTemplate'">
          <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
          </dx-button>
        </div>
      </dx-data-grid>
    </div>
    <div *ngIf="!selectedTargetItemId" class="col-md-6">
      <div class="label">Hedef Ürünler</div>
      <dx-data-grid
        class="dx-card wide-card"
        [dataSource]="targetItemList"
        keyExpr="id"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [columnHidingEnabled]="true"
        [(selectedRowKeys)]="selectedTargetItems"
      >
        <dxo-selection
          selectAllMode="allPages"
          showCheckBoxesMode="always"
          mode="multiple"
        ></dxo-selection>
        <dxo-paging [pageSize]="8"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxi-column
          dataField="Resim"
          caption="#"
          [width]="80"
          [allowGrouping]="false"
          dataField="thumb"
          [allowFiltering]="false"
          [allowHiding]="false"
          [allowSearch]="false"
          [allowSorting]="false"
          cellTemplate="imageTemplate"
        >
        </dxi-column>

        <dxi-column dataField="name" caption="Ürün"> </dxi-column>
        <dxi-column dataField="categoryName" caption="Kategori"> </dxi-column>

        <dxi-column
          dataField="status"
          caption="Durum"
          [width]="180"
          cellTemplate="statusTemplate"
        >
          <dxo-lookup
            [dataSource]="lookupStatus"
            valueExpr="id"
            displayExpr="name"
          ></dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let data of 'statusTemplate'">
          <app-status-badge [status]="data.value"></app-status-badge>
        </div>

        <div *dxTemplate="let data of 'providerTemplate'">
          <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
          </dx-button>
        </div>

        <div *dxTemplate="let data of 'imageTemplate'">
          <img
            class="placeholder-img mx-2"
            [src]="
              data.value
                ? environment.fileEndpoint + data.value
                : 'assets/placeholder.png'
            "
            alt=""
          />
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
