import { Component } from "@angular/core";
import notify from "devextreme/ui/notify";
import { HttpService } from "src/app/shared/services/http.service";

@Component({
  selector: "app-password-change",
  templateUrl: `./password-change.component.html`,
  styleUrls: ["./password-change.component.scss"],
})
export class PasswordChangeComponent {
    oldPassword: any; 
    newPassword: any; 
    againNewPassword: any; 
    
  loading: boolean = false;
  constructor(private httpService: HttpService) {}

  passwordChange() {
    if(!(this.oldPassword && this.newPassword && this.againNewPassword)) {
        notify(
            {
              message: "Tüm alanları eksiksiz doldurunuz.",
              width: 450,
            },
            "error",
            2000
          );
        return;
    }

    if(!(this.newPassword === this.againNewPassword)) {
        notify(
            {
              message: "Yeni parolalar eşleşmiyor.",
              width: 450,
            },
            "error",
            2000
          );
        return;
    }
    const requestBody = {
        oldPassword : this.oldPassword,
        newPassword : this.newPassword,
        againNewPassword : this.againNewPassword
    };
    this.httpService.post(`user/passwordChange`, requestBody).subscribe((res) => {
            this.oldPassword = "";
            this.newPassword = "";
            this.againNewPassword = "";
            notify(
                {
                  message: "İşlem başarılı.",
                  width: 450,
                },
                "success",
                2000
              );
          },
          (e) => {}
    );
  }
}