import { Component, OnInit } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { OrderCancelReasonRequestDto } from "src/app/shared/models/OrderCancelReason";

@Component({
  selector: "app-cancel-reason",
  templateUrl: `./cancel-reason.html`,
  styleUrls: ["./cancel-reason.scss"],
})
export class CancelReasonComponent implements OnInit {
  dataSource: any[] = [];


  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  request: OrderCancelReasonRequestDto = new OrderCancelReasonRequestDto();


  get isFormValid(): boolean {
    return this.request.name && this.request.name.length > 0 ;
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getOrderCancelReasons();
  }

  getOrderCancelReasons() {
    this.httpService
      .get(`OrderCancelReason`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.request = new OrderCancelReasonRequestDto();
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`OrderCancelReason/${id}`).subscribe(res => {
      this.request = res;
      this.isPopupVisible = true;
    })
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`OrderCancelReason/${id}`).subscribe(res => {
      this.getOrderCancelReasons();
    })
  }



  onInit = function(e:any)  {  
    debugger;
    e.component.registerKeyHandler("escape", function (arg) {  
        arg.stopPropagation();  
    });  
}  

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.request.id) {
      this.httpService.put('OrderCancelReason', this.request).subscribe(res => {
        this.getOrderCancelReasons();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('OrderCancelReason', this.request).subscribe(res => {
        this.getOrderCancelReasons();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.request = new OrderCancelReasonRequestDto();
  }.bind(this);

}
