import { Component, OnInit } from "@angular/core";
import StatusUtils from "src/app/shared/models/status.enum"; // Status lookup bilgisi
import { HttpService } from "src/app/shared/services/http.service"; // API çağrıları için servis
import { confirm } from "devextreme/ui/dialog"; // Silme işlemi için onay popup'ı
import { UserRequestDto, RoleUtils } from "src/app/shared/models/user.model";

@Component({
  selector: "app-admins",
  templateUrl: `./user-list.component.html`,
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
  dataSource: any[] = [];
  lookupStatus = StatusUtils.StatusNamesLookup; // Durum gösterimi için lookup

  isPopupVisible: boolean = false; // Popup görünürlük kontrolü
  userRequestDto: UserRequestDto = new UserRequestDto(); // Formdaki veri için DTO
  roleUtils =  RoleUtils;
  roleLookups =  RoleUtils.RolesLookup;
  isEditClicked: boolean = false;

  get isFormValid(): boolean {
    // Form geçerlilik kontrolü
    return (
      this.userRequestDto.name &&
      this.userRequestDto.name.length > 0 &&
      this.userRequestDto.eMail &&
      this.userRequestDto.eMail.length > 0 &&      
      this.userRequestDto.phone &&
      this.userRequestDto.phone.length > 0 &&
      this.userRequestDto.role !== undefined
    );
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.httpService
      .get(`User`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`User/${id}`).subscribe((res) => {
      this.userRequestDto = res;
      this.openPopup();
      this.isEditClicked = true;
    });
  }

  async onDeleteClicked(id: string) {
    let result = await confirm(
      "Kullanıcıyı silmek istediğinizden emin misiniz?",
      "Silme Onayı"
    );
    if (!result) {
      return;
    }

    this.httpService.delete(`User/${id}`).subscribe(() => {
      this.getUsers();
    });
  }

  onSubmitClicked = function () {
    if (!this.isFormValid) {
      return;
    }

    if (this.userRequestDto.id) {
      // Güncelleme
      this.httpService.put("User", this.userRequestDto).subscribe(() => {
        this.getUsers();
        this.onCloseClicked();
      });
    } else {
      // Yeni ekleme
      this.httpService.post("User", this.userRequestDto).subscribe(() => {
        this.getUsers();
        this.onCloseClicked();
      });
    }
  }.bind(this);

  onCloseClicked = function () {
    this.isPopupVisible = false;
    this.userRequestDto = new UserRequestDto(); // Formu temizle
    this.isEditClicked = false;
  }.bind(this);
}
