import { Status } from "./status.enum";

export class TableResourceDto {
    id: string | null;
    number: number;
    name: string;
    status: Status | null = Status.Active
}


export class TableRequestDto {
    id: string | null;
    number: number;
    name: string;
    status: Status | null = Status.Active

}