
export enum PaymentMethod {
    CashOnDelivery,
    EWallet,
    CreditCardOnDelivery,
    CreditCardOnline
}

export default class PaymentMethodUtils { 

static GetPaymentMethodName = (paymentMethod: PaymentMethod) => {
    return this.PaymentMethodNamesLookup.find(x => x.id == PaymentMethodUtils.PaymentMethods[paymentMethod])?.name;
};

   static PaymentMethods = [
            PaymentMethod.CashOnDelivery, 
            PaymentMethod.EWallet, 
            PaymentMethod.CreditCardOnDelivery, 
            PaymentMethod.CreditCardOnline
        ];


        static PaymentMethodNamesLookup = [ 
            {id: PaymentMethod.CashOnDelivery, name: 'Kapıda Nakit' }, 
            {id: PaymentMethod.EWallet, name: 'Dijital Cüzdan' }, 
            {id: PaymentMethod.CreditCardOnDelivery, name: 'Kapıda Kredi Kartı' }, 
            {id: PaymentMethod.CreditCardOnline, name: 'Online Kredi Kartı' }, 

        ]
    }

     